// @ts-nocheck
import { Provider } from '@angular/core';
import { NG_DOC_CONTEXT, NG_DOC_ROUTE_PREFIX, NG_DOC_SHIKI_THEME } from '@ng-doc/app/tokens';

export function provideNgDocContext(): Provider[] {
  return [{
    provide: NG_DOC_CONTEXT,
    useValue: {
      navigation: [
          
        {
          title: `Getting Started`,
          route: '/docs/getting-started',
          expandable: true,
          expanded: false,
          order: 1,
          hidden: false,
          metadata: {"description":"","tags":{}},
          children: [
              
        {
          title: `What is NgDoc?`,
          route: '/docs/getting-started/what-is-ng-doc',
          order: 1,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
              
        {
          title: `Installation`,
          route: '/docs/getting-started/installation',
          order: 2,
          hidden: false,
          metadata: {"description":"<p>NgDoc doesn&#39;t serve as a standalone application, but rather as a tool that can be integrated into\nyour application to generate documentation. This means that you will need to create a new Angular\napplication, or use an existing one, and install NgDoc as a dependency.</p>","tags":{}},
        },
              
        {
          title: `Configuration`,
          route: '/docs/getting-started/configuration',
          order: 3,
          hidden: false,
          metadata: {"description":"<p>This section describes how you can configure certain functions of the library.</p>","tags":{"status":[":success NEW OPTIONS"]}},
        },
              
        {
          title: `SSR`,
          route: '/docs/getting-started/ssr',
          order: 3,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
              
        {
          title: `Migrations`,
          route: '/docs/getting-started/migrations',
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
          ]
        },
          
        {
          title: `Writing content`,
          route: '/docs/writing-content',
          expandable: true,
          expanded: false,
          order: 2,
          hidden: false,
          metadata: {"description":"","tags":{}},
          children: [
              
        {
          title: `Pages and categories`,
          route: '/docs/writing-content/pages-and-categories',
          order: 1,
          hidden: false,
          metadata: {"description":"","tags":{"status":[":success UPDATED"]}},
        },
              
        {
          title: `Blockquotes`,
          route: '/docs/writing-content/blockquotes',
          order: 2,
          hidden: false,
          metadata: {"description":"","tags":{"status":[":success NEW STYLES"]}},
        },
              
        {
          title: `Images and embeds`,
          route: '/docs/writing-content/images-videos-and-embeds',
          order: 3,
          hidden: false,
          metadata: {"description":"","tags":{"status":[":info NEW"]}},
        },
              
        {
          title: `Code blocks`,
          route: '/docs/writing-content/code-blocks',
          order: 4,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
              
        {
          title: `Mermaid diagrams`,
          route: '/docs/writing-content/mermaid-diagrams',
          order: 5,
          hidden: false,
          metadata: {"description":"","tags":{"status":[":info NEW"]}},
        },
              
        {
          title: `Nunjucks`,
          route: '/docs/writing-content/nunjucks',
          order: 6,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
              
        {
          title: `Demo and Demo Pane`,
          route: '/docs/writing-content/demo-and-demo-pane',
          order: 7,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
              
        {
          title: `Playground`,
          route: '/docs/writing-content/playground',
          order: 8,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
              
        {
          title: `Keywords`,
          route: '/docs/writing-content/keywords',
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
          ]
        },
          
        {
          title: `API Documentation`,
          route: '/docs/api-documentation',
          expandable: true,
          expanded: false,
          order: 4,
          hidden: false,
          metadata: {"description":"","tags":{}},
          children: [
              
        {
          title: `Auto generation`,
          route: '/docs/api-documentation/auto-generation',
          order: 1,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
              
        {
          title: `Documenting declarations`,
          route: '/docs/api-documentation/documenting-declarations',
          order: 2,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
              
        {
          title: `Rendering API`,
          route: '/docs/api-documentation/rendering-api',
          hidden: false,
          metadata: {"description":"","tags":{"status":[":info NEW"]}},
        },
          ]
        },
          
        {
          title: `Customization`,
          route: '/docs/customization',
          expandable: true,
          expanded: false,
          order: 6,
          hidden: false,
          metadata: {"description":"","tags":{}},
          children: [
              
        {
          title: `Page Processors`,
          route: '/docs/customization/page-processors',
          order: 1,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
              
        {
          title: `Page Skeleton`,
          route: '/docs/customization/page-skeleton',
          order: 1,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
              
        {
          title: `Search`,
          route: '/docs/customization/search',
          order: 2,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
              
        {
          title: `Layout`,
          route: '/docs/customization/layout',
          order: 2,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
              
        {
          title: `Themes`,
          route: '/docs/customization/themes',
          order: 3,
          hidden: false,
          metadata: {"description":"","tags":{"status":[":warning UPDATED"]}},
        },
              
        {
          title: `Icons`,
          route: '/docs/customization/icons',
          order: 4,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
          ]
        },
          
        {
          title: `Recipes`,
          route: '/docs/recipes',
          expandable: true,
          expanded: false,
          order: 7,
          hidden: false,
          metadata: {"description":"","tags":{}},
          children: [
              
        {
          title: `Performance`,
          route: '/docs/recipes/performance',
          order: 3,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
              
        {
          title: `Ignoring file lines`,
          route: '/docs/recipes/ignoring-file-lines',
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
          ]
        },
          
        {
          title: `API References`,
          route: '/docs/api',
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
          
        {
          title: `Develop`,
          route: '/docs/develop',
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
      ],
    },
  }, {
    provide: NG_DOC_ROUTE_PREFIX,
    useValue: 'docs',
  }, {
    provide: NG_DOC_SHIKI_THEME,
    useValue: {
      light: '',
      dark: '',
    },
  }];
}
